import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class CanActivateViaOauthGuardService  {

  constructor(public router: Router) { }

  canActivate() {
    if (sessionStorage.getItem('token') === null) {
      this.router.navigateByUrl('/login');
    }

    return (sessionStorage.getItem('token') === null) ? false : true;
  }

}
