import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../core/login.service';


@Component({
  selector: 'ad-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})

export class NotfoundComponent  implements OnInit {
  constructor(
    public router: Router,
    public loginService: LoginService
  ) { }

  ngOnInit() {
    this.loginService.logout();
    this.router.navigateByUrl('/login');
  }
}
