import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { CoreModule } from './core/core.module';
import { NotfoundComponent } from './notfound/notfound.component';
import { EndpointsComponent } from './endpoints/endpoints.component';
import { SharedModule } from './shared/shared.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppCommunicationService } from './app-communication.service';
import { SnackBarErrorComponent } from './snack-bar-error/snack-bar-error.component';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './custom-date-adapter';


@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        EndpointsComponent,
        NotfoundComponent,
        SnackBarErrorComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        MatSnackBarModule], providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: LOCALE_ID, useValue: 'es-ES' },
        AppCommunicationService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
