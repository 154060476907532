import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../core/api/api.service';
import { environment } from '../../environments/environment';
import { LoginService } from '../core/login.service';

@Component({
  selector: 'ad-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  errorLogin = false;

  constructor(public router: Router, private _loginService: LoginService, private _apiService: ApiService) { }

  login(event, username, password) {
    this.errorLogin = false;
    event.preventDefault();
    this._loginService.login(username, password)
      .subscribe(
        response => {
          sessionStorage.setItem('token', response.oauth_consumer_key);
          sessionStorage.setItem('permisos', JSON.stringify(response.permissions));
          const user_permission = JSON.parse(sessionStorage.getItem('permisos'));
          this._apiService.init(environment.baseApi, response.oauth_consumer_key);
          if (response.user_type === 2) {
            this.router.navigateByUrl('/teacher-portal/teacher');
          } else {
            this.router.navigateByUrl('/gestor');
          }
        },
        error => {
          this.errorLogin = true;
        }
      );
  }

}
