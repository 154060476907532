<div class="error-container">
  <div class="upper-container">
    <div class="icon-container">
      <mat-icon>error_outline</mat-icon>
    </div>
    <h1>Error</h1>
    <div class="dismiss-container">
      <a href (click)="cierra(); false">
        <mat-icon>close</mat-icon>
      </a>
    </div>
  </div>
  <p [innerHtml]="data">
  </p>
</div>
