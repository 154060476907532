import { Component, Inject, Optional } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'ad-snack-bar-error',
  templateUrl: './snack-bar-error.component.html',
  styleUrls: ['./snack-bar-error.component.scss']
})
export class SnackBarErrorComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private _snack: MatSnackBarRef<any>
  ) { }

  cierra() {
    this._snack.dismiss();
  }

}
