import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

@Injectable()
export class CanActivateTeacherGuardService  {

  constructor(
    public router: Router,
    public loginService: LoginService
  ) { }

  canActivate() {
    this.loginService.loggedUser.subscribe(usuario => {
      if (usuario.tipo_usuario !== 2) {
        this.loginService.logout();
        this.router.navigateByUrl('/login');
      }
    });

    return true;
  }

}
