import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanActivateViaOauthGuardService } from './can-activate-via-oauth-guard.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApiService } from './api/api.service';
import { LoginService } from './login.service';
import { CanActivateGestorGuardService } from './can-activate-gestor-guard.service';
import { CanActivateTeacherGuardService } from './can-activate-teacher-guard.service';

@NgModule({ imports: [CommonModule], providers: [
        CanActivateViaOauthGuardService,
        CanActivateGestorGuardService,
        CanActivateTeacherGuardService,
        ApiService,
        LoginService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule {}
