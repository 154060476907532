<div class="">
  <div class="mdc-layout-grid__inner">
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-9-desktop visible-desktop">
      <div class="image">
        <div class="layer centrado text-centered">
          <div class="info-image">
              <h1>Sistema de gestión académica</h1>
              <h2>Instituto Franklin-UAH</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-8-tablet login-right">
      <div class="logo-container">
        <img src="assets/img/logo_if.png">
      </div>
      <mat-card class="login-box">
        <div class="error-login" *ngIf="errorLogin">
          Error: Usuario o contraseña incorrectos. Póngase en contacto con el administrador.
        </div>
        <form role="form" (submit)="login($event, username.value, password.value)">
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Usuario</mat-label>
              <input type="text" matInput #username>
              <mat-icon color="primary" matSuffix>face</mat-icon>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Contraseña</mat-label>
              <input type="password" matInput #password>
              <mat-icon color="primary" matSuffix>lock</mat-icon>
            </mat-form-field>
          </div>
          <div class="text-right">
            <button type="submit" mat-button color="primary">Iniciar sesión</button>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
