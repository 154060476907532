import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class CanActivateGestorGuardService  {

  constructor(
    public router: Router,
    public loginService: LoginService
  ) { }

  canActivate(): Observable<boolean> {
    return this.loginService.loggedUser.pipe(
      map(usuario => {
        if (!usuario) {
          this.router.navigateByUrl('/login');
          return false;
        }
        if (usuario.tipo_usuario !== 1) {
          this.loginService.logout();
          this.router.navigateByUrl('/login');
          return false;
        }
        return true;
      }),
      catchError(() => {
        this.router.navigateByUrl('/login');
        return of(false);
      })
    );
  }

}
