import { Component } from '@angular/core';
import { AppCommunicationService } from './app-communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarErrorComponent } from './snack-bar-error/snack-bar-error.component';

@Component({
  selector: 'ad-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ad';
  snackBarErrorComponent: SnackBarErrorComponent;

  constructor(
    private _appCommunicationService: AppCommunicationService,
    private _snackBar: MatSnackBar
  ) {
    _appCommunicationService.snackErrorMessage$.subscribe(
      message => {
        this._snackBar.openFromComponent(
          SnackBarErrorComponent,
          {
            duration: 10000,
            data: message,
            panelClass: ['snack-bar-error']
          });
      }
    );
  }
}
