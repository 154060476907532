import { Component, OnInit } from '@angular/core';
import { ApiService } from '../core/api/api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ad-endpoints',
  templateUrl: './endpoints.component.html',
  styleUrls: ['./endpoints.component.scss']
})
export class EndpointsComponent implements OnInit {

  constructor(
    private _apiService: ApiService,
    private http: HttpClient
  ) { }

  endpoints;
  error;

  //displayedColumns = ['fieldName', 'helpText', 'type', 'default', 'readonly'];
  displayedColumns = ['fieldName', 'type', 'default', 'readonly'];

  list() {
    this.limpiarError();
    this.http.get(environment.baseApi)
      .subscribe(
        (res: any) => {
          this.endpoints = [];
          // tslint:disable-next-line:forin
          for (const key in res) {
            this.endpoints.push({key: key, value: res[key], endpoint: null});
          }
        },
        (err) => { 
          this.error = {'border' : '2px solid red'}; 
          this.endpoints = null; 
        }
      );
  }

  onTabOpen(index: number) {
    if (!this.endpoints[index].endpoint) {
      const resourceService = this._apiService.getResourceService(this.endpoints[index].key);
      resourceService.schema()
        .subscribe(
          (res) => {
            this.endpoints[index].endpoint = {
              fields: new TableDataSource(res),
              allowedDetail: res.allowed_detail_http_methods,
              allowedList: res.allowed_list_http_methods,
            };
          },
          (err) => {
            this.endpoints[index].endpoint = {
              error: err
            };
          }
        );
    }
  }

  schema(resource) {
    this.limpiarError();
    if (resource !== '') {
      const resourceService = this._apiService.getResourceService(resource);
      resourceService.schema().subscribe(
        (res) => {
          this.endpoints = res;
        },
        (err) => { 
          this.error = {'border' : '2px solid red'}; 
          this.endpoints = err; 
        }
      );
    } else {
      this.endpoints = null;
    }
  }

  showJson(e, index) {
    e.preventDefault();
    this.endpoints[index].showJson = !this.endpoints[index].showJson;
  }

  private limpiarError() {
    this.error = {'border': 'none'};
  }

  ngOnInit() {
    this.list();
  }
}

export class TableDataSource extends DataSource<any> {
  private _data = [];

  public constructor(source) {
    super();
    this._setData(source);
  }

  private _setData(source) {
    // tslint:disable-next-line:forin
    for (const field in source.fields) {
      const row = source.fields[field];
      this._data.push({
        fieldName: field,
        helpText: row.help_text,
        type: row.type,
        default: row.default,
        readonly: row.readonly
      });
    }
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Element[]> {
    return of(this._data);
  }

  disconnect() {}
}
