
<div class="ui-g">
  <h1 style="margin-top: 0">Endpoints</h1>
  <div class="ui-g-12">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of endpoints; let indexItem = index"  (onOpen)="onTabOpen(indexItem)">
        <mat-expansion-panel-header>
          {{ item.key }}
        </mat-expansion-panel-header>
        <mat-expansion-panel-body>
          <div *ngIf="item.endpoint">
            <ng-container *ngIf="!item.endpoint.error">
              <h3>Métodos permitidos para los detalles:</h3>
              <ul>
                <li *ngFor="let method of item.endpoint.allowedDetail">
                  {{method}}
                </li>
              </ul>
              <h3>Métodos permitidos para el listado:</h3>
              <ul>
                <li *ngFor="let method of item.endpoint.allowedList">
                  {{method}}
                </li>
              </ul>

              <mat-table #table [dataSource]="item.endpoint.fields">
                <ng-container matColumnDef="fieldName">
                  <mat-header-cell *matHeaderCellDef> No </mat-header-cell>
                  <mat-cell *matCellDef="let row">{{row.fieldName}}</mat-cell>
                </ng-container>
                <!--<ng-container matColumnDef="helpText">
                  <mat-header-cell *matHeaderCellDef> Ayuda </mat-header-cell>
                  <mat-cell *matCellDef="let row">{{row.helpText}}</mat-cell>
                </ng-container>-->
                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef> Tipo </mat-header-cell>
                  <mat-cell *matCellDef="let row">{{row.type}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="default">
                  <mat-header-cell *matHeaderCellDef> Valor por defecto </mat-header-cell>
                  <mat-cell *matCellDef="let row">{{row.default}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="readonly">
                  <mat-header-cell *matHeaderCellDef>Sólo lectura</mat-header-cell>
                  <mat-cell *matCellDef="let row">{{row.readonly?'Sí':'No'}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
            </ng-container>
            <ng-container *ngIf="item.endpoint.error">
              <pre class="error">{{item.endpoint.error|json}}</pre>
            </ng-container>
          </div>
        </mat-expansion-panel-body>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
