import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CanActivateViaOauthGuardService } from './core/can-activate-via-oauth-guard.service';
import { EndpointsComponent } from './endpoints/endpoints.component';
import { CanActivateGestorGuardService } from './core/can-activate-gestor-guard.service';
import { NotfoundComponent } from './notfound/notfound.component';

const routes: Routes = [
  {
    path: 'gestor',
    loadChildren: () => import('app/gestor-academico/gestor-academico.module').then(m => m.GestorAcademicoModule),
    canActivate: [CanActivateViaOauthGuardService, CanActivateGestorGuardService]
  },
  {
    path: 'endpoints',
    component: EndpointsComponent,
    canActivate: [CanActivateViaOauthGuardService]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'teacher-portal',
    loadChildren: () => import('app/teacher-portal/teacher-portal.module').then(m => m.TeacherPortalModule),
    canActivate: [CanActivateViaOauthGuardService]
  },
  {
    path: '',
    redirectTo: '/gestor',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotfoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
